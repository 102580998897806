// @flow

import React from "react";
import {
    AssignmentLayout,
    Layout,
    Communication,
    withPrivateRoute,
} from "@containers";

/**
 * Communication
 */
const Emails = (props: *) => (
    <Layout {...props}>
        <AssignmentLayout title="E-mails">
            <Communication />
        </AssignmentLayout>
    </Layout>
);

export default withPrivateRoute(Emails, "/opdrachten/:inspectionId/emails");
